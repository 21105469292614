import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { fullArtistContacts } from "~/queries/artistContactsQuery"; // eslint-disable-line
import get from "lodash/get";
import IndexBlock from "../components/IndexBlock";
import PaginationBlock from "../components/PaginationBlockAtom";
import SEO from "../components/seo";

class ArtistsIndex extends Component {
	render() {
		const posts = get(this, "props.data.recraft.entries");
		const { currentPage, numPages } = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const prevPage =
			currentPage - 1 === 1
				? "artists/"
				: "artists/" + (currentPage - 1).toString();
		const nextPage = "artists/" + (currentPage + 1).toString();
		const featuredPost = posts[0];
		return (
			<Layout>
				<SEO title="Artists" keywords={[`dumbo`, `places`, `dumbo artists`]} />
				<IndexBlock posts={posts} featuredPost={featuredPost} />

				<PaginationBlock
					nextPage={nextPage}
					prevPage={prevPage}
					numPages={numPages}
					currentPage={currentPage}
					isFirst={isFirst}
					isLast={isLast}
					linkWord={"artists"}
				/>
			</Layout>
		);
	}
}

export default ArtistsIndex;

export const artistsListQuery = graphql`
	query artistsListQuery($offset: Int!, $limit: Int!) {
		recraft {
			entries(section: [artists], limit: $limit, offset: $offset) {
				... on Recraft_Artists {
					title
					uri
					slug
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					body {
						content
					}
					postDate
					primaryContact {
						phone
					}
					addressSelector {
						... on Recraft_Addresses {
							streetNumber
							streetName
							streetType
							title
						}
					}
				}
				...fullArtistContacts
			}
		}
	}
`;

// const posts = get(this, "props.data.craft.entries");

// orderBy: "title asc"

// <div className="w-full flex px-4 mb-6">
// 	<h2>Title</h2>
// 	<h4>{totalCount}</h4>
// </div>;
